.TopbarContainer {
    width: 100%;
    height: 9%;
    background-color: #23262b;
    position: sticky;
    top: 0;
    z-index: 1000;
}

.TopbarContent {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    padding: 0 5%;
    height: 100%;
}

@media screen and (max-width: 950px) {
    .TopbarContent {
        padding: 0 3%;
    }
}

.TopbarContentImg {
    width: 60px;
    height: auto;
}

.TopbarContentList {
    flex-grow: 1;
    width: 100%;
    padding-left: 3rem;
}

.TopbarContentListButton {
    width: calc(100%/5);
}

@media screen and (min-width: 600px) {
    .TopbarContentMenuIcon {
        display: none !important;
    }
}


@media screen and (min-width: 600px) {
    .TopbarContent {
        justify-content: space-between;
    }
}

@media screen and (max-width: 600px) {
    .TopbarContentList {
        display: none;
    }

    /* .TopbarContent {
        justify-content: center;
    } */

    .TopbarContentImg {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }

    .TopbarContent {
        padding: 0;
    }

    .TopbarContentMenuIcon {
        width: 60px !important;
        height: auto;
        color: #6c758a;
    }
}