.HomeWrapper {
    flex-grow: 1;
    width: 100%;
    height: 91%;
    z-index: 100;
}

.HomeContainer {
    width: 100%;
    height: 100%;
}

.HomeContent {
    width: 100%;
    height: 100%;
}