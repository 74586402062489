#scene {
    perspective: 1000px;
    width: 200px;
    height: 200px;
    top: 50vh;
    left: 50vw;
    transform: translateX(-50%) translateY(-50%);
}

.boxBase {
    transform-style: preserve-3d;
    width: 200px;
    height: 200px;
}

@keyframes turnAround {
    0% {
        transform: rotateX(0deg) rotateY(0deg);
    }

    100% {
        transform: rotateX(360deg) rotateY(360deg);
    }
}

@keyframes flowRightToLeft {
    0% {
        transform: translateX(0vw) translateY(0);
    }

    100% {
        transform: translateX(-50vw) translateY(-50px);
    }
}

.boxBase>div {
    position: absolute;
    background-color: rgba(100, 100, 100, 0.7);
    border: 0.5px solid black;
    width: 100%;
    height: 100%;
}

.boxBase>.top {
    transform: translateY(100px) rotateX(-90deg);
}

.boxBase>.bottom {
    transform: translateY(-100px) rotateX(90deg);
}

.boxBase>.front {
    transform: translateZ(100px);
}

.boxBase>.back {
    transform: translateZ(-100px) rotateX(180deg);
}

.boxBase>.left {
    transform: translateX(-100px) rotateY(-90deg);
}

.boxBase>.right {
    transform: translateX(100px) rotateY(90deg);
}

.CubeContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.CubeContainer p {
    padding-bottom: 0.3rem;
}

@media screen and (max-width: 1520px) {
    .CubeContainer p {
        font-size: 13px;
    }
}

@media screen and (max-width: 920px) {
    .CubeContainer p {
        font-size: 10px;
    }
}

@media screen and (max-width: 800px) {
    .CubeContainer p {
        font-size: 8px;
    }
}

@media screen and (max-width: 700px) {
    .CubeContainer p {
        font-size: 13px;
    }

    .CubeContainer {
        text-align: center;
    }
}

@media screen and (max-width: 600px) {
    .CubeContainer p {
        font-size: 11px;
    }
}

@media screen and (max-width: 500px) {
    .CubeContainer p {
        font-size: 9px;
    }
}

@media screen and (max-width: 350px) {
    .CubeContainer p {
        font-size: 8px;
    }
}

.CubeContentImg {
    width: 100%;
    height: auto;
}

.CubeContentTitle {
    padding-top: 1rem;
}

.CubeContent {
    flex-grow: 1;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.CubeContentP {
    font-size: 14px;
}

.boxBaseButton,
.turnAround {
    background: none !important;
    border: none !important;
    outline: none !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.boxBaseButton :active {
    background-color: none;
    outline: none;
    border-color: transparent;
}

@media screen and (max-width: 700px) {
    .CubeContentDiv {
        display: none;
    }
}