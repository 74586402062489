.TopWrapper {
    height: 100%;
}

.TopContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    text-align: right;
    justify-content: start;
    background-image: url(../../../public/assets/top.jpg);
    background-position: center;
    /* 画像の中心を基準にする */
    background-size: cover;
    /* 画像をコンテナに合わせて調整 */
}

.TopContainer h1 {
    padding: 5% 10% 0 0;
    color: #d2d262;
    font-size: 8em !important;
}

@media screen and (max-width: 1550px) {
    .TopContainer h1 {
        font-size: 7em !important;
    }
}

@media screen and (max-width: 1250px) {
    .TopContainer h1 {
        font-size: 6em !important;
    }
}

@media screen and (max-width: 1100px) {
    .TopContainer h1 {
        font-size: 5em !important;
    }
}

@media screen and (max-width: 820px) {
    .TopContainer h1 {
        font-size: 4em !important;
    }
}

@media screen and (max-width: 650px) {
    .TopContainer h1 {
        font-size: 3em !important;
    }
}

@media screen and (max-width: 600px) {
    .TopContainer h1 {
        padding: 0 0 9vh 0;
    }
}

@media screen and (max-width: 600px) {
    .TopContainer {
        text-align: center;
        justify-content: center;
    }
}