.ResumeSidebar ul {
    padding: 0 !important;
}

.ResumeSidebar li {
    list-style: none !important;
}

@media (min-width: 48em) {
    .ResumeSidebar {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        width: 18rem;
        text-align: left;
    }

    .ResumeSidebarSticky {
        position: absolute;
        right: 1rem;
        bottom: 1rem;
        left: 1rem;
    }
}

@media (max-width: 48em) {
    .ResumeSidebarSticky {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .ResumeSidebarSticky button h1 {
        text-align: center;
    }
}

.ResumeSidebar {
    display: flex;
    flex-direction: column;
    padding: 2rem 1rem;
}

.ResumeSidebarH1 h1 {
    color: #0075C2 !important;
    text-align: left;
    margin-bottom: 0.5rem;
}

.ResumeSidebarUl {
    padding-left: 0.8rem;
    margin-bottom: 1rem;
}

.ResumeSidebarUl p {
    font-size: 20px;
    line-height: 1.5;
    color: #585858;
}

@media (min-width: 48em) and (max-width: 58em) {
    .ResumeSidebar {
        width: 18rem
    }

    .ResumeSidebarUl p {
        font-size: 16px;
    }

    .ResumeSidebar h1 {
        font-size: 1.5rem !important;
    }
}

.ResumeSidebarUl {
    list-style: none;
}

@media print {

    .ResumeSidebar,
    time {
        display: none !important;
    }
}