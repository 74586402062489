.ProfileWrapper {
    height: calc(100% - 2rem);
    width: calc(100% - 2rem);
    padding: 1rem;
    background-color: rgb(82, 85, 54);
    display: flex;
    flex-direction: column;
}

.ProfileContentTitle {
    margin-top: 4rem;
    margin-left: calc(3.5em + 1rem);
    display: flex;
    align-items: center;
    /* 中央揃え */
    justify-content: center;
}

.githubLinkContainer {
    background-color: white;
    border-radius: 50%;
    height: 3.5em;
    width: 3.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1rem;
    border: none;
    color: transparent;
    outline: none;
}

.githubLinkContainer:active,
.githubLinkContainer:visited,
.githubLinkContainer:focus {
    outline: none;
    border: none;
    color: transparent;
    background-color: white;
    box-shadow: none;
}

.githubLink {
    width: auto;
    height: 55%;
    object-fit: contain;
}

.ProfileContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.ProfileContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: calc(100% - 5rem - 1.5em - 4rem);
    margin-top: 4rem;
    flex-grow: 1;
}

@media screen and (max-height: 740px),
screen and (max-width: 600px) {
    .ProfileWrapper {
        height: calc(100% - 2rem);
        /* padding: 4rem; */
    }

    .ProfileContentTitle {
        margin-top: 2rem;
    }

    .ProfileContent {
        margin-top: 2rem;
    }
}

.ProfileContainImg {
    flex-grow: 1;
    height: 100%;
    width: auto;
    object-fit: contain;
    margin-right: 1rem;
}

@media screen and (max-width: 960px) {
    .ProfileContainImg {
        display: none;
    }
}

.ProfileMyHistoryAndMySkill {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.ProfileMyHistoryAndMySkill h2 {
    text-align: center;
    margin-bottom: 0.5rem;
}

.ProfileMyHistoryUl li {
    display: flex;
    flex-direction: column;
    align-items: left;
    margin-bottom: 3px;
}

.ProfileMyHistory {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    text-align: left;
    margin-bottom: 2rem;
}

.ProfileMyHistoryLiP {
    margin-left: 5rem;
}

@media screen and (max-width: 1130px) {
    .ProfileMyHistoryLiP {
        margin-left: 3rem;
    }
}

@media screen and (max-width: 960px) {
    .ProfileMyHistoryLiP {
        margin-left: 2rem;
    }
}

@media screen and (min-width: 960px) {
    .ProfileMySkill {
        flex-grow: 1;
    }
}

.ProfileMySkill {
    display: flex;
    flex-direction: column;
}

.ProfileMySkillContainer {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}

.ProfileMySkillContent {
    width: calc(100% / 5);
    height: calc(100% - 6px);
    background-color: #282c34;
}

.ProfileMySkillContentRight {
    margin-right: 1rem;
}

.ProfileMySkillContentList {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0.5rem;
    width: calc(100% - 1rem);
}

.ProfileMySkillContentListButton {
    width: 70%;
}

@media (max-width: 750px),
(min-width: 960px) and (max-width: 1100px) {
    .ProfileMySkillContentListButton {
        width: 90%;
    }
}

@media screen and (max-width: 600px) {
    .ProfileMySkillContentListButton {
        width: 100%;
    }
}

.ProfileMySkillContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.ProfileMySkillContentBlockCenter {
    justify-content: center;
}

.ProfileMySkillContentBlockLeft,
.ProfileMySkillContentBlockCenter,
.ProfileMySkillContentBlockRight {
    width: calc(100% / 3 - 1rem);
}

.ProfileMySkillContentBlockLeft>div,
.ProfileMySkillContentBlockCenter>div,
.ProfileMySkillContentBlockRight>div {
    width: calc(100% - 6px);
    height: calc(50% + 1rem);
}

.ProfileMySkillContentBlockLeft,
.ProfileMySkillContentBlockCenter,
.ProfileMySkillContentBlockRight {
    display: none;
}

.ProfileMySkillContentBlock {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-evenly;
}

.ProfileMySkillContentWholeBlock {
    display: none;
}

@media screen and (max-width: 1600px) {

    .ProfileMySkillContentBlock {
        display: none;
    }

    /* コメントアウトされていた要素を表示する */
    .ProfileMySkillContentBlockLeft,
    .ProfileMySkillContentBlockCenter,
    .ProfileMySkillContentBlockRight {
        display: flex;
        flex-direction: column;
    }
}

@media screen and (max-width: 560px) {

    .ProfileMySkillContentBlockLeft,
    .ProfileMySkillContentBlockCenter,
    .ProfileMySkillContentBlockRight {
        display: none;
    }

    .ProfileMySkillContentWholeBlock {
        display: block;
        width: 100%;
    }
}

.MySkillTopBlock {
    margin-bottom: 16px;
}

@media screen and (max-width: 1600px) {

    p,
    span {
        font-size: 15px;
    }
}

@media screen and (max-width: 1300px) {

    p,
    span {
        font-size: 14px;
    }
}

@media screen and (max-width: 800px) {

    p,
    span {
        font-size: 13px;
    }
}

.ProfileMySkillContentDiv li {
    list-style: inside;
    text-align: left;
    padding-top: 1rem;
}

.ProfileMySkillContentWholeUl {
    /* padding-top: 1rem; */
    display: flex;
    align-items: start;
    justify-content: space-evenly;
    padding-bottom: 1rem;
}

.ProfileMySkillContentWholeUl p {
    text-align: left;
    margin-left: 1rem;
    /* color: black; */
}

@media screen and (max-height: 715px) {
    .ProfileContent {
        margin-top: 1rem;
    }

    .ProfileMyHistory {
        margin-bottom: 1rem;
    }

    .ProfileMySkillContentDiv li {
        padding-top: 0.5rem;
    }

    .ProfileMySkillContentWholeUl {
        padding-bottom: 0.5rem;
    }
}

.ResumeButton {
    background: none !important;
    border: none !important;
    outline: none !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-shadow: none;
}

.ResumeButton:active {
    background-color: none;
    outline: none;
    border-color: transparent;
    box-shadow: none !important;
    text-decoration: underline;
}

.ProfileMyHistoryTitle {
    display: flex;
    align-items: center;
    justify-content: center;
}

.ProfileMyHistoryTitle h2 {
    margin-left: 75px;
}