.AboutWrapper {
    height: calc(100% - 2rem);
    padding: 1rem;
}

.AboutContainer h1 {
    margin-top: 4rem;
}

.AboutContent {
    margin-top: 4rem;
}

.AboutContent p {
    margin-bottom: 4rem;
    font-size: 20px;
}

@media screen and (max-height: 720px),
screen and (max-width: 600px) {
    .AboutWrapper {
        height: calc(100% - 2rem);
        /* padding: 4rem; */
    }

    .AboutWrapper h1 {
        margin-top: 2rem;
    }

    .AboutContent {
        margin-top: 2rem;
    }
}

@media screen and (max-width: 1400px) {
    .AboutContent p {
        font-size: 18px;
    }
}

@media screen and (max-width: 1280px) {
    .AboutContent p {
        font-size: 16px;
    }
}

@media screen and (max-width: 1140px) {
    .AboutContent p {
        font-size: 15px;
    }
}

@media screen and (max-width: 1060px) {
    .AboutContent p {
        font-size: 14px;
    }
}

.AboutContent p {
    line-height: 30px;
}

@media screen and (max-height: 770px) {
    .AboutContent p {
        line-height: 25px;
        margin-bottom: 3.5rem;
    }
}

@media screen and (max-height: 720px) {
    .AboutContent p {
        line-height: 22px;
    }
}

@media screen and (max-height: 700px) {
    .AboutContent p {
        margin-bottom: 3rem;
    }
}

@media screen and (max-height: 635px) {
    .AboutContent p {
        margin-bottom: 2.5rem;
    }
}

@media screen and (max-height: 605px) {
    .AboutContent p {
        margin-bottom: 2rem;
    }
}

@media screen and (max-height: 590px) {
    .AboutContent p {
        margin-bottom: 1.5rem;
    }
}