.BlogRightbar {
    /* background-color: #f6f6f6f6; */
}

@media (min-width: 74em) {
    .BlogRightbar {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        width: 18rem;
        text-align: left;
    }

    .BlogRightbarSticky {
        position: absolute;
        right: 1rem;
        bottom: 1rem;
        left: 1rem;
    }
}

@media (min-width: 90em) {
    .BlogRightbar {
        width: 26rem;
    }
}

@media (min-width: 100em) {
    .BlogRightbar {
        width: 32rem;
    }
}

@media (max-width: 74em) {
    .BlogRightbar {
        display: none;
    }

    .BlogRightbarSticky {
        display: none;
    }
}