.ModalWorkWrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001;
}

.ModalWorkContainer {
    background-color: white;
    height: 70%;
    width: 60%;
    padding: 1rem;
    border-radius: 4px;
    position: relative;
    z-index: 1000;
    cursor: default;
    overflow-y: scroll;
    display: flex;
    justify-content: space-between;
}

@media screen and (max-width: 500px) {
    .ModalWorkContainer {
        width: 75%;
    }
}

.ModalWorkContentTitle {
    display: none;
}

.ModalWorkContentImgSp {
    display: none;
}

@media screen and (max-width: 800px) {
    .ModalWorkContainer {
        flex-direction: column;
        align-items: center;
        /* justify-content: center; */
    }

    .ModalWorkContentImgSp {
        display: block;
        width: 60%;
        height: auto;
        object-fit: contain;
        box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0);
    }

    .ModalWorkContentTitle {
        display: flex;
        color: #424242;
        margin-bottom: 1rem;
    }
}

.ModalWorkContentImg {
    width: 50%;
    height: auto;
    object-fit: contain;
}

.ModalWorkContent {
    width: calc(50% - 2rem);
    padding: 0 1rem 1rem 1rem;
}

@media screen and (max-width: 800px) {
    .ModalWorkContent {
        width: calc(100% - 2rem);
        /* height: 100%; */
    }

    .ModalWorkContentImg {
        display: none;
    }
}

.ModalWorkContent h3 {
    position: relative;
    text-align: center;
    margin: 20px 0;
}

.ModalWorkContent h3::before {
    content: '';
    position: absolute;
    top: 50%;
    width: 100%;
    height: 1px;
    background-color: #ddd;
    z-index: 1;
}

.ModalWorkContent h3::before {
    left: 0;
    z-index: 1;
}

.ModalWorkContentH3P {
    position: relative;
    display: inline-block;
    z-index: 2;
    background-color: white;
    padding: 0 1rem;
    color: #424242;
}

.ModalWorkContent p {
    color: #424242;
}

.ModalWorkContentP {
    text-align: left;
    font-size: 13px;
    white-space: normal;
    word-break: break-word;
    overflow-wrap: break-word;
}

.testSite {
    display: flex;
    align-items: center;
}

@media screen and (max-width: 410px) {
    .testSite {
        display: grid;
    }
}