.BlogWrapper {
    width: 100%;
    height: 100vh;
    font-family: Avenir Next, "Helvetica Neue", -apple-system, sans-serif;
}

.BlogWrapper ul {
    padding-inline-start: 40px;
}

.BlogWrapper li {
    list-style-type: disc;
    margin: 0.2rem 0;
}

.BlogWrapper div,
.BlogWrapper time,
.BlogWrapper p,
.BlogWrapper span,
.BlogWrapper h1,
.BlogWrapper h2,
.BlogWrapper h3,
.BlogWrapper h4 {
    color: #585858;
}

.BlogWrapper h1 {
    font-size: 2rem !important;
}

.BlogWrapper h2 {
    font-size: 1.5rem !important;
}

.BlogWrapper h3 {
    font-size: 1.25rem !important;
}

.BlogWrapper h4 {
    font-size: 1rem !important;
}

.BlogWrapper button {
    background: none !important;
    border: none !important;
    outline: none !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-shadow: none;
    text-shadow: none;
    font-family: Avenir Next, "Helvetica Neue", -apple-system, sans-serif;
    padding: 0;
    min-width: auto !important;
    min-height: auto !important;
    font-size: inherit;
}

.BlogWrapper button:active {
    background-color: none;
    outline: none;
    border-color: transparent;
    box-shadow: none !important;
    text-decoration: underline;
}

.BlogWrapper p {
    color: #585858;
    padding: 0;
    margin: 0;
    text-align: left;
}

/* .BlogWrapper a {
    color: #585858;
    text-decoration: none;
} */


@media (min-width: 48em) {
    .BlogContainer {
        max-width: 58rem;
        width: calc(100% - 20rem);
        margin-left: 18rem;
        margin-right: 2rem;
    }
}

@media (min-width: 58em) {
    .BlogContainer {
        width: calc(100% - 20rem);
        margin-left: 20rem;
        margin-right: 0;
    }
}

@media (min-width: 64em) {

    .BlogContainer {
        width: calc(100% - 34rem);
        margin-left: 28rem;
        margin-right: 6rem;
    }
}

@media (min-width: 74em) {
    .BlogContainer {
        width: calc(100% - 48rem);
        margin-left: 28rem;
        margin-right: 20rem;
    }
}

@media (min-width: 90em) {
    .BlogContainer {
        width: calc(100% - 56rem);
        margin-left: 28rem;
        margin-right: 28rem;
    }
}

@media (min-width: 100em) {
    .BlogContainer {
        width: calc(100% - 62rem);
        margin-left: 28rem;
        margin-right: 34rem;
    }
}


.BlogContainer {
    padding-top: 4rem;
    padding-bottom: 4rem;
}

.Articles {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    /* 記事の間隔を調整 */
}

.Article {
    background: #fff;
    /* 記事の背景色、必要に応じて調整 */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* 軽い影を追加 */
    padding: 20px;
    /* 内側の余白 */
    border-radius: 5px;
    /* 角丸 */
}

.ArticleTag {
    display: inline-block;
    background-color: #007bff;
    color: #ffffff;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 0.8em;
    margin-top: 10px;
}

@media screen and (max-width: 768px) {
    .Articles {
        grid-template-columns: 1fr;
        /* 画面が768px以下の場合は1列に変更 */
    }
}

.SearchBox {
    background-color: #ffffff;
    border: 1px solid #bdbdbd;
    border-radius: 5px;
    height: 35px;
    width: calc(30% - 9.6px);
    min-width: 250px;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.SearchBox svg {
    font-size: calc(35px * 0.8);
    color: #bdbdbd;
    padding: 0 0.4rem;
    position: relative;
    top: 1px;
}

.SearchBox input {
    box-shadow: none;
    border: none;
    height: calc(100% - 16px - 4px);
    width: calc(100% - 50.38px);
    padding: 8px 16px 8px 0;
    font-size: 16px;
}

.SearchStatus {
    display: flex;
    align-items: center;
}

.HasMoreButtonDiv {
    /* background-color: red; */
    margin: 4rem 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.HasMoreButtonDiv button {
    padding: 0.7rem 2rem;
    background-color: 8, 19, 26, 0.1411764705882353 !important;
    border: 1px solid #bdbdbd !important;
    border-radius: 4px;
}