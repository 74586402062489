.WorksWrapper {
    height: calc(100% - 2rem);
    padding: 1rem;
}

.WorksContainer h1 {
    margin-top: 4rem;
}

.WorksContent {
    margin-top: 4rem;
    /* display: flex; */
}

@media screen and (max-height: 740px),
screen and (max-width: 600px) {
    .WorksWrapper {
        height: calc(100% - 2rem);
        /* padding: 4rem; */
    }

    .WorksWrapper h1 {
        margin-top: 2rem;
    }

    .WorksContent {
        margin-top: 2rem;
    }
}


.WorksContent button {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    outline: none;
    cursor: pointer;
    text-align: left;
    font: inherit;
    color: inherit;
    width: auto;
    height: auto;
    box-shadow: none;
}

.WorksContent {
    display: flex;
}

.WorksContentButton {
    -webkit-tap-highlight-color: transparent;
}

.WorksContentButton:active {
    background-color: transparent;
    border-color: transparent !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    text-shadow: none !important;
}