.ModalMenuWrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1100;
}

.ModalMenuContainer {
    z-index: 1000;
    width: 60%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    /* background-color: white; */
    display: flex;
    flex-direction: column;
    padding-left: 0;
    background-color: #282c34;

    box-shadow: 9px 0 5px -5px rgba(0, 0, 0, 0.6);
}

@media screen and (max-width: 560px) {
    .ModalMenuContainer {
        width: 61%;
    }
}

@media screen and (max-width: 550px) {
    .ModalMenuContainer {
        width: 83%;
    }
}

.ModalMenuContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
}

.ModalMenuContentTitle {
    display: flex;
    align-items: center;
}

.ModalMenuContentTitleImg {
    width: 60px;
    height: auto;
}

.ModalMenuContentUl {
    width: 100%;
    padding: 1rem 0;
}

.ModalMenuContentLi {
    width: 100%;
}

.ModalMenuContentLiButton {
    width: 80%;
    height: 40px;
    margin-bottom: 0.5rem;
}